import {ResolveFn} from '@angular/router';
import {inject} from "@angular/core";
import {PageService} from "./page.service";
import {catchError, of} from "rxjs";
import {Page} from "./types/page";

export const pageResolver: ResolveFn<Page|null> = (route, state) => {
  let slug = route.paramMap.get('slug');

  if (!slug) {
    slug = 'homepage'
  }

  slug = decodeURIComponent(slug);

  if (slug.includes('#')) {
    slug = slug.split('#')[0];
  }

  return inject(PageService).getPageBySlug(slug).pipe(
    catchError(() => of(null))
  );
};
