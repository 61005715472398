import {AfterViewInit, Component} from '@angular/core';
import {AuthService} from "./auth/auth.service";
import * as CookieConsent from 'vanilla-cookieconsent';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements AfterViewInit {
  title = 'telemann';

  public constructor(private authService: AuthService) {
  }

  public isLoggedIn(): boolean {
    return true;
    // return this.authService.isLoggedIn();
  }

  ngAfterViewInit(): void {
    CookieConsent.run({
      guiOptions: {
        consentModal: {
          layout: 'bar',
          position: 'bottom center',
          equalWeightButtons: true,
          flipButtons: false,
        },
        preferencesModal: {
          layout: 'box',
          equalWeightButtons: true,
          flipButtons: false,
        },
      },

      categories: {
        necessary: {
          enabled: true,
          readOnly: true,
        },
        functional: {
          services: {
            youtube: {
              label: 'YouTube',
            },
          },
        },
        analytics: {
          services: {
            matomo: {
              label: 'Matomo',
            },
          },

        },
      },
      language: {
        default: 'de',
        translations: {
          de: {
            consentModal: {
              title: 'Cookies',
              description: `Wir verwenden unverzichtbare Cookies, damit die Website telemann.de ordnungsgemäß funktioniert.
                            Außerdem würden wir gerne Funktions-Cookies nutzen, um Ihr Website-Erlebnis
                            zu optimieren und zu personalisieren. Auch möchten wir die Leistung unserer
                            Website analysieren, insofern Sie zustimmen. In unserer Cookie-Richtlinie
                            finden Sie weitere Informationen zu Ihren Auswahlmöglichkeiten.<br /><br />
                            Cookies und ähnliche Technologien sammeln bestimmte Informationen darüber, wie Sie unsere
                            Website nutzen. Einige davon sind unbedingt erforderlich, und ohne diese können Sie diese
                            Website nicht verwenden. Andere sind optional und Sie können selbst entscheiden, ob wir
                            sie verwenden dürfen oder nicht.`,
              acceptAllBtn: 'Alle akzeptieren',
              acceptNecessaryBtn: 'Alle ablehnen',
              showPreferencesBtn: 'Individuelle Datenschutzeinstellungen'
            },
            preferencesModal: {
              title: 'Einstellungen',
              acceptAllBtn: 'Alle akzeptieren',
              acceptNecessaryBtn: 'Alle ablehnen',
              savePreferencesBtn: 'Aktuelle Auswahl annehmen',
              closeIconLabel: 'Schließen',
              sections: [
                {
                  title: 'Technisch notwendige Cookies',
                  description: `Diese Cookies sind immer aktiv, da sie für das Funktionieren und die Sicherheit unserer Website telemann.de unerlässlich sind. Ohne diese Cookies können die von Ihnen angeforderten Dienste nicht bereit gestellt werden.`,
                  linkedCategory: 'necessary'
                },
                {
                  title: 'Funktionale Einstellungen',
                  description: `Diese Cookies helfen uns, Ihnen eine verbesserte Funktionalität und Personalisierung zu bieten und Ihre Einstellungen zu speichern. Sie können von uns oder von Drittanbietern eingerichtet werden.`,
                  linkedCategory: 'functional',
                },
                {
                  title: 'Statistik / Matomo zulassen',
                  description: `Diese Cookies helfen uns zu analysieren, wie viele Personen die Website telemann.de besuchen, woher sie kommen und wie Sie die Website nutzen. Wenn Sie diese Cookies deaktivieren, können wir kein Feedback erhalten, um unser Webangebot für Sie und alle Nutzenden zu verbessern.`,
                  linkedCategory: 'analytics'
                }
              ]
            }
          }
        }
      }
    });
  }
}
