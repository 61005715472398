<footer>
  <div class="container container-boxed">
    <app-footer-menu></app-footer-menu>
    <app-social></app-social>
  </div>
</footer>
<div class="copyrights">
  {{ copyrights }}
  <img class="w-16" src="assets/layout/logo_magdeburg.jpg">
  <img class="w-16" src="assets/layout/logo.svg">
</div>
