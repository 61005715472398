<div class="container container-boxed text-center" style="margin-top: 30px;">
  <app-widget-title [widget]="title"></app-widget-title>
  <app-widget-text [widget]="text"></app-widget-text>
  <app-widget-separator [widget]="separator"></app-widget-separator>
  <div class="not-found--links">
    <app-widget-button [widget]="button1"></app-widget-button>
    <app-widget-button [widget]="button2"></app-widget-button>
    <app-widget-button [widget]="button3"></app-widget-button>
  </div>
</div>
