import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Page} from "./types/page";
import {map, Observable} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {LayoutComponent} from "../page-builder/layout/layout/layout.component";
import {Meta, Title} from "@angular/platform-browser";
import {Layout} from "./types/layout";
import {NotFoundComponent} from "../not-found/not-found.component";

@Component({
  selector: 'app-page',
  standalone: true,
  imports: [CommonModule, LayoutComponent, NotFoundComponent],
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.less']
})
export class PageComponent implements OnInit, OnDestroy {

  public page$!: Observable<Page>;
  protected pageNotFound: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private titleService: Title,
    private meta: Meta,
  ) {
  }

  ngOnInit(): void {
    this.page$ = this.route.data.pipe(
      map(
        page => page['page']
      )
    );

    this.page$.subscribe(page => {
      this.meta.removeTag('name="description"');

      if (page === null) {
        this.pageNotFound = true;
        this.titleService.setTitle('404 Nicht gefunden');
      } else {
        this.pageNotFound = false;
        this.titleService.setTitle(page.title);
        if (page.metaDescription) {
          this.meta.addTag({name: 'description', content: page.metaDescription});
        }

        const hash = window.location.hash;
        if (hash) {
          setTimeout(() => {
            const scrollToElement = document.getElementById(hash.substring(1));
            if (scrollToElement) {
              let position = scrollToElement.getBoundingClientRect();
              window.scrollTo({
                left: position.left,
                top: position.top + window.scrollY - 80,
                behavior: 'smooth'
              });
            }
          }, 2000)
        }
      }
    })
  }

  ngOnDestroy(): void {
    this.meta.removeTag('name="description"');
  }

  protected sortedLayouts(page: Page) {
    return page.layouts.sort((a: Layout, b: Layout) => a.position - b.position);
  }
}
