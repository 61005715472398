<app-not-found *ngIf="pageNotFound"></app-not-found>

<ng-container *ngIf="!pageNotFound && (event$ | async) as event">
  <div class="event-details">
    <header class="event-header" [title]="getCopyrights(event)" *ngIf="event.imageUrl">
      <div class="event-header--background-image" [ngStyle]="getHeaderStyle(event)">
      </div>
      <div class="event-header--image--container container">
        <img class="event-header--image" src="{{event.imageUrl}}" alt="{{event.name}}">
      </div>
    </header>

    <div class="container container-boxed">
      <article class="event-details--page">
        <div class="event-details--content-top">
          <div class="title-and-subtitle">
            <h2>{{ event.name }}</h2>
            <h3>{{ event.subtitle }}</h3>
          </div>
          <div class="event-details--tickets">
            <div *ngIf="getEventPrices(event) as prices" class="event-details--price" [innerHtml]="prices"></div>
            <div *ngIf="event.ticketUrl" class="event-details--ticket-button">
              <a target="_blank" [href]="event.ticketUrl" class="button button-default">Karten kaufen</a>
            </div>
          </div>
        </div>

        <div class="event-details--info">
          <p class="event-details--date">
            <time>
              <strong>{{ event.startTime | date:'EEEE, dd. MMMM YYYY' }}</strong>
              | {{ event.startTime | date:'HH.mm' }} Uhr
            </time>
          </p>
          <p>{{ event.location }}</p>
        </div>

        <div class="separator separator-short"></div>

        <div *ngIf="event.artists" class="event-details--artists">
          <ul>
            <li *ngFor="let artist of event.artists" class="event-artist">
              <div class="event-artist--details">
                <span class="event-artist--name">{{ artist.name }}</span>
                <span *ngIf="artist.instrument" class="event-artist--instrument">{{ artist.instrument }}</span>
              </div>
              <div *ngIf="artist.isGroup" class="event-artist-group--members">
                <ul>
                  <li *ngFor="let member of artist.members">
                    <span class="event-artist--name">{{ member.name }}</span>
                    <span *ngIf="member.instrument" class="event-artist--instrument">{{ member.instrument }}</span>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <div class="event-details--content" [innerHtml]="event.description">
        </div>

        <div class="event-details--sponsors" *ngIf="event.sponsors?.length">
          <h3 [innerHtml]="sponsorsTitle(event)"></h3>
          <div class="event-sponsors--list">
            <div *ngFor="let sponsor of event.sponsors" class="event-sponsor--details">
              <a target="_blank" [href]="sponsor.url">
                <img [src]="sponsorLogoUrl(sponsor)" [alt]="sponsor.name"
                     [title]="sponsor.name"/>
              </a>
            </div>
          </div>
        </div>

      </article>
    </div>
  </div>
</ng-container>
