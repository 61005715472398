import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EmbedCode} from "./types/embed-code";
import {ReactiveFormsModule} from "@angular/forms";
import {SafePipe} from "../../../pipes/safe/safe.pipe";

@Component({
  selector: 'app-widget-embed-code',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SafePipe],
  templateUrl: './embed-code.component.html',
})
export class EmbedCodeComponent {
  @Input() widget!: EmbedCode;
}
