<app-not-found *ngIf="pageNotFound"></app-not-found>

<ng-container *ngIf="!pageNotFound && (blog$ | async) as blog">
  <div class="blog-details">
    <header class="blog-header" [title]="blog.title" *ngIf="blog.imageUrl">
      <div class="blog-header--background-image" [ngStyle]="getHeaderStyle(blog)">
      </div>
      <div class="blog-header--image--container container">
        <img class="blog-header--image" src="{{blog.imageUrl}}" alt="{{blog.title}}">
      </div>
    </header>

    <div class="container container-boxed">
      <article class="blog-details--page">
        <div class="blog-details--content-top">
          <div class="title-and-subtitle">
            <h2>{{ blog.title }}</h2>
          </div>
        </div>

        <div *ngIf="blog.dateCreated" class="blog-details--info">
          <p class="blog-details--date">
            <time>
              Veröffentlicht am <strong>{{ blog.dateCreated | date:'dd.MM.YYYY' }}</strong>
            </time>
          </p>
        </div>

        <div class="separator separator-short"></div>

        <div class="blog-details--content" [innerHtml]="blog.text | safe">
        </div>

      </article>

      <div class="button-widget button-widget--align-center">
        <a class="button button-default" [routerLink]="['/', 'aktuelles']">Aktuelles</a>
      </div>
    </div>
  </div>
</ng-container>
