import {ResolveFn} from '@angular/router';
import {inject} from "@angular/core";
import {EventsService} from "./events.service";
import {Event} from "./types/event";
import {catchError, of} from "rxjs";

export const eventResolver: ResolveFn<Event | null> = (route, state) => {
  return inject(EventsService).getEventBySlug(route.paramMap.get('slug') ?? '').pipe(
    catchError(() => of(null))
  );
};
