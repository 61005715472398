import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EventsService} from "../../../events/events.service";
import {EventCategoryListItem} from "../../../events/types/event-category-list-item";
import {SlickCarouselComponent, SlickCarouselModule} from "ngx-slick-carousel";
import {CategoryFilterItem} from "./types/category-filter-item";
import {Event, EventArtist} from "../../../events/types/event";
import {RouterLink} from "@angular/router";
import {EventFilters} from "./types/event-filters";
import {EventsFilterResponse} from "../../../events/types/events-filter-response";
import {EventsCalendar} from "./types/events-calendar";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-widget-events-calendar',
  standalone: true,
  imports: [CommonModule, SlickCarouselModule, RouterLink],
  templateUrl: './events-calendar.component.html',
  styleUrls: [
    './events-calendar.component.less',
    '../../../../styles/events/_events-list.less'
  ]
})
export class EventsCalendarComponent implements OnInit, AfterViewInit {

  @Input() widget !: EventsCalendar;
  @ViewChild('yearsSlider') yearsSlider!: SlickCarouselComponent;

  public slideConfig = {
    infinite: false,
    slidesToShow: 10,
    slidesToScroll: 3,
    responsive: [{
      breakpoint: 1024, settings: {
        slidesToShow: 6,
      }
    }, {
      breakpoint: 600, settings: {
        slidesToShow: 4, slidesToScroll: 2
      }
    }, {
      breakpoint: 480, settings: {
        slidesToShow: 3, slidesToScroll: 2
      }
    }]
  };

  public yearsSlideConfig = {
    infinite: false,
    slidesToShow: 'auto',
    arrows: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          variableWidth: true,
          centerPadding: '20px',
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }]
  };

  public categories!: CategoryFilterItem[];
  public events!: Event[];
  public eventDates!: string[];
  public viewMode!: 'list' | 'grid';
  public selectedDay: string | null = null;
  public selectedYear: number | null = null;
  private filtersUnchanged: boolean = true;

  private eventFilters: EventFilters = {
    categoryIds: [],
    dateFrom: `${new Date().getFullYear()}-01-01 00:00:00`,
    dateTo: `${new Date().getFullYear()}-12-31 23:59:59`
  };

  protected trackByFn(index: number, item: string) {
    return index;
  };

  public constructor(
    private eventsService: EventsService,
  ) {
  }

  ngOnInit(): void {
    this.viewMode = this.widget.data.view ?? 'list';
    this.selectedYear = (new Date()).getFullYear();

    this.eventsService.getCategoriesList()
      .subscribe(
        (categories: EventCategoryListItem[]) => {
          this.categories = categories.map((c) => {
            return {
              category: c,
              selected: false,
            }
          })
        }
      );

    this.loadEvents();
  }

  ngAfterViewInit() {
    this.yearsSlider.slickGoTo(
      this.widget.data.years.findIndex(y => y === this.selectedYear),
    );
  }

  private loadEvents(): void {
    this.eventsService.getEvents(
      this.eventFilters.categoryIds,
      this.eventFilters.dateFrom,
      this.eventFilters.dateTo,
    )
      .subscribe(
        (response: EventsFilterResponse) => {
          this.events = response.events;
          this.eventDates = response.days;
        }
      );
  }

  public getCategoryButtonClasses(category: EventCategoryListItem): string[] {
    return [
      `button-event-category-style--${category.style}`
    ]
  }

  public getEventArtists(event: Event): string {
    return event.artists.filter(m => m.name).map(a => this.getArtistText(a)).join(', ');
  }

  private getArtistText(artist: EventArtist) {
    if (!artist.isGroup) {
      return artist.name.trim();
    }

    return `<strong>${artist.name}</strong> | ${artist.members.filter(m => m.name).map(m => m.name.trim()).join(', ')}`;
  }

  public getEventStyle(event: Event): string[] {
    return [
      `event-style--${event.style}`
    ];
  }

  public getEventImageUrl(event: Event): string | null {
    if (!event.image) {
      return null;
    }

    return `url(${environment.cdn.url}/events/thumbnails/medium/${event.image})`;
  }

  public getEventCopyrights(event: Event): string {
    return event.copyrights || event.name;
  }

  public getEventDisplayName(event: Event): string {
    return event.name;
  }

  public filterBySelectedDate(event: Event): boolean {
    if (this.noFilters()) {
      const eventDate = new Date(event.startTime);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (eventDate < today) {
        return false;
      }
    }
    return this.selectedDay === null || event.startTime.startsWith(this.selectedDay);
  }

  public onClickCategoryFilter(category: CategoryFilterItem): void {
    this.filtersUnchanged = false;
    category.selected = !category.selected;
    this.eventFilters.categoryIds = this.categories.filter(c => c.selected).map(c => c.category.id);
    this.loadEvents();
  }

  public onClickYearFilter(year: number) {
    this.filtersUnchanged = false;
    this.eventFilters.dateFrom = `${year}-01-01`;
    this.eventFilters.dateTo = `${year}-12-31 23:59:59`;
    this.selectedDay = null;
    this.selectedYear = year;
    this.loadEvents()
  }

  public onClickEventDay(day: string): void {
    if (this.selectedDay === day) {
      this.selectedDay = null;
      return;
    }

    this.selectedDay = day;
  }

  public setViewMode(value: 'list' | 'grid'): void {
    this.viewMode = value;
  }

  private noFilters(): boolean {
    return this.filtersUnchanged && !this.selectedDay;
  }
}
