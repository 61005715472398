import {ResolveFn} from '@angular/router';
import {inject} from "@angular/core";
import {BlogService} from "./blog.service";
import {BlogPost} from "./types/blog-post";
import {catchError, of} from "rxjs";

export const blogResolver: ResolveFn<BlogPost | null> = (route, state) => {
  return inject(BlogService).getBlogBySlug(route.paramMap.get('slug') ?? '').pipe(
    catchError(() => of(null))
  );
};
