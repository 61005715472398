import {Component} from '@angular/core';
import {SeparatorComponent} from "../page-builder/widget/separator/separator.component";
import {Separator} from "../page-builder/widget/separator/types/separator";
import {Text} from "../page-builder/widget/text/types/text";
import {TextComponent} from "../page-builder/widget/text/text.component";
import {Title} from "../page-builder/widget/title/types/title";
import {TitleComponent} from "../page-builder/widget/title/title.component";
import {Button} from "../page-builder/widget/button/types/button";
import {ButtonComponent} from "../page-builder/widget/button/button.component";

@Component({
  standalone: true,
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  imports: [
    SeparatorComponent,
    TitleComponent,
    TextComponent,
    ButtonComponent
  ],
  styleUrls: ['./not-found.component.less']
})
export class NotFoundComponent {
  protected title: Title = {
    id: 1,
    position: 0,
    type: 'title',
    data: {
      title: 'Hoppla, die Seite wurde nicht gefunden!',
      element: 'h1',
      textAlign: 'center'
    }
  };
  protected separator: Separator = {
    id: 2,
    position: 0,
    type: 'separator',
    data: {
      align: 'center',
      size: 'small'
    }
  };
  protected text: Text = {
    id: 3,
    position: 0,
    type: 'text',
    data: {
      text: 'Es sieht so aus, als wäre die Seite, die Sie suchen, nicht mehr verfügbar oder die Seite hat nie existiert.',
      textAlign: 'center',
    }
  };
  protected button1: Button = {
    id: 3,
    position: 0,
    type: 'button',
    data: {
      text: 'Zurück zur Startseite',
      url: '/homepage',
      align: 'center',
      style: 'default',
    }
  };
  protected button2: Button = {
    id: 4,
    position: 0,
    type: 'button',
    data: {
      text: 'Veranstaltungen',
      url: '/veranstaltungen/kalender',
      align: 'center',
      style: 'white',
    }
  };
  protected button3: Button = {
    id: 5,
    position: 0,
    type: 'button',
    data: {
      text: 'Aktuelles',
      url: '/aktuelles',
      align: 'center',
      style: 'white',
    }
  };

}
