import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Video} from "./types/video";
import {YouTubePlayer, YouTubePlayerModule} from "@angular/youtube-player";
import {YoutubePlayerStatus} from "./enum/youtube-player-status";
import {environment} from "../../../../environments/environment";
import {VideoService} from "../../../video/video.service";

@Component({
  selector: 'app-widget-video',
  standalone: true,
  imports: [CommonModule, YouTubePlayerModule],
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.less']
})
export class VideoComponent implements OnInit {
  @Input() widget!: Video;
  @ViewChild('player', {static: false}) player!: YouTubePlayer;

  isPlaying: boolean = false;

  public constructor(
    private videoService: VideoService,
  ) {
  }

  ngOnInit() {
    this.videoService.getVideoStartedEvent().subscribe(event => {
      this.pauseVideo();
    });
  }

  public playVideo(): void {
    this.videoService.sendVideoStartedEvent();
    this.player.playVideo();
    this.isPlaying = true;
  }

  public pauseVideo(): void {
    this.player.pauseVideo();
    this.isPlaying = false;
  }

  onPlayerStateChange(e: any): void {
    switch (e.data) {
      case YoutubePlayerStatus.Playing:
      case YoutubePlayerStatus.Buffering:
        this.isPlaying = true;
        break;
      default:
        this.isPlaying = false;
    }
  }

  public getClasses(): string[] {
    return [
      `video-widget--style-${this.widget.data.style}`
    ];
  }

  public getThumbnailStyle(): object {
    return {
      backgroundImage: `url(${environment.cdn.url}/cdn/thumbnails/media/${this.widget.data.thumbnail})`
    }
  }

  public getVideoId(): string {
    try {
      const params = new URL(this.widget.data.url).searchParams;
      return params.get('v') ?? '';
    } catch (e) {
      return ''
    }
  }
}
