import {Component, Input, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Blog} from "./types/blog";
import {RouterLink} from "@angular/router";
import {BlogPost} from 'src/app/blog/types/blog-post';
import {BlogService} from 'src/app/blog/blog.service';
import {environment} from "../../../../environments/environment";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
  selector: 'app-widget-blog',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.less']
})
export class BlogComponent implements OnInit {
  @Input() widget!: Blog;
  public blogPosts: BlogPost[] = [];

  public constructor(
    private blogService: BlogService,
    private sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit(): void {
    this.blogService.getBlogPosts(
      this.widget.data.filters?.categories,
      this.widget.data.limit,
    ).subscribe(
      posts => this.blogPosts = posts
    )
  }

  public excerpt(article: BlogPost, limit: number = 450): SafeHtml {
    if (!article.text) return '';

    const div = document.createElement('div');
    div.innerHTML = article.text;

    let charCount = 0;
    const truncateNodes = (node: Node): boolean => {
      if (node.nodeType === Node.TEXT_NODE) {
        charCount += node.textContent?.length ?? 0;
        if (charCount > limit) {
          node.textContent = node.textContent?.substring(0, node.textContent.length - (charCount - limit)) + '...';
          return true;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        for (let i = 0; i < node.childNodes.length; i++) {
          if (truncateNodes(node.childNodes[i])) {
            while (node.childNodes.length > i + 1) {
              node.removeChild(node.childNodes[i + 1]);
            }
            return true;
          }
        }
      }
      return false;
    };

    truncateNodes(div);

    return this.sanitizer.bypassSecurityTrustHtml(div.innerHTML);
  }

  public getStyles(article: BlogPost): object {
    return {
      backgroundImage: `url(${environment.cdn.url}/blogs/thumbnails/medium/${article.image})`
    }
  }
}
